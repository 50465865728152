import React from "react"
import clStyles from "./classLinks.module.scss"
import { Link } from "gatsby"

export default () => (
  <div className={clStyles.classlinks}>
    <h3>Public classes</h3>
      <ul>
        <li>
          <Link to="/bachata-mondays">
            <span>Bachata Mondays</span>
          </Link>
        </li>
        <li>
          <Link to="/salsa-wednesdays">
            <span>Salsa Wednesdays</span>
          </Link>
        </li>
        <li>
          <Link to="/bachata-thursdays">
            <span>Bachata Thursdays</span>
          </Link>
        </li>
      </ul>
  </div>
)